class Validator
{
  value = ''
    
	static functions = []
	static serverFunctions = []
    
  constructor(value, root)
  {
		this.value = value
		this.root = root

    return new Proxy(this, {
      get: function (target, prop) {
        if (typeof target[prop] !== 'undefined')
          return target[prop]

        return function () {
          let callback = this.functions[prop] !== undefined ? this.functions[prop] : this.serverFunctions[prop]
            return callback.apply(target, Array.prototype.slice.call(arguments))
        }
      }
    })
	}

  // TODO: zastapic method_exists
	static hasValidator(name)
	{
		return this.functions[name] !== undefined || this.serverFunctions[name] !== undefined || true //|| method_exists(get_called_class(), name)
	}

	static registerFunction(name, callback)
	{
		this.functions[name] = callback
	}

	static registerServerFunction(name, callback)
	{
		this.serverFunctions[name] = callback
	}

	static isServer(name)
	{
		return this.serverFunctions[name] != undefined
	}
}

export {
    Validator
}