import React, {useState, useEffect} from 'react'

import Layout from 'layouts/Main'

import {redirectTo} from '@reach/router'

import Attendee from './Attendee'
import Student from './Student'
import Media from './Media'
import Partner from './Partner'
import Volunteer from './Volunteer'
import Prelegent from './Prelegent'

export default function HashPage({hash}) {

    const [user, setUser] = useState(false)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/get_info.php?hash=${hash}&lang=${process.env.REACT_APP_LANG}`)
        .then((data) => data.json())
        .then((data) => {
            if (!data.error) {
                setUser(data)
            }
        })
    }, [])

    if (!user) {
        return <div></div>
    }

    switch (user.rodzaj_rejestracji)
    {
        case 'Prelegent':
            return <Prelegent data={user} />
            break;
        case 'Partner':
            return <Partner data={user} />
            break;
        case 'Wolontariat':
            return <Volunteer data={user} />
            break;
        case 'Media':
            return <Media data={user} />
            break;
        case 'Student':
            return <Student data={user} />
            break;
        case 'Uczestnik':
            return <Attendee data={user} />
            break;
    }
}