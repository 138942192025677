class Type
{
	options = {}
	validators = {}

	required = false
	requiredWhenExist = false
	requiredWhenNotExist = false
	requiredWhenIn = false
	requiredWhenNotIn = false
	requiredWhenEqual = false
	requiredWhenNotEqual = false
	requiredWhenGreater = false
	requiredWhenLower = false


	// TODO: dodac sprawdzanie czy istnieje dany validator zanim sie go doda...
    constructor()
    {
        return new Proxy(this, {
            get: function (target, prop) {
                if (typeof target[prop] !== 'undefined')
                    return target[prop]

                return function () {
                    //var strFn = this.type.charAt(0).toUpperCase() + this.type.substring(1) + "Validator"
					target.validators[prop] = Array.prototype.slice.call(arguments);
					return target
                }
            }
        })
    }

	_required()
	{
		this.required = true;
		this.requiredWhenExist = false;
		this.requiredWhenNotExist = false;
		this.requiredWhenIn = false;
		this.requiredWhenNotIn = false;
		this.requiredWhenEqual = false;
		this.requiredWhenNotEqual = false;
		this.requiredWhenLower = false;
		this.requiredWhenGreater = false;

		return this;
	}

	_requiredWhenExist(field)
	{
		this.required = false;
		this.requiredWhenExist = [field];
		return this;
	}

	_requiredWhenNotExist(field)
	{
		this.required = false;
		this.requiredWhenNotExist = [field];
		return this;
	}

	_requiredWhenEqual(field, value)
	{
		this.required = false;
		this.requiredWhenEqual = [field, value];
		return this;
	}

	_requiredWhenNotEqual(field, value)
	{
		this.required = false;
		this.requiredWhenNotEqual = [field, value];
		return this;
	}

	_requiredWhenIn(field, value)
	{
		this.required = false;
		this.requiredWhenIn = [field, value];
		return this;
	}

	_requiredWhenNotIn(field, value)
	{
		this.required = false;
		this.requiredWhenNotIn = [field, value];
		return this;
	}

	_requiredWhenLower(field, value)
	{
		this.required = false;
		this.requiredWhenLower = [field, value];
		return this;
	}

	_requiredWhenGreater(field, value)
	{
		this.required = false;
		this.requiredWhenGreater = [field, value];
		return this;
	}

	schema()
	{
		return Object.assign({
			'type': this.type,
			'required': this.required,
			'requiredWhenEqual': this.requiredWhenEqual,
			'requiredWhenExist': this.requiredWhenExist,
			'requiredWhenNotExist': this.requiredWhenNotExist,
			'requiredWhenIn': this.requiredWhenIn,
			'requiredWhenNotIn': this.requiredWhenNotIn,
			'requiredWhenNotEqual': this.requiredWhenNotEqual,
			'requiredWhenGreater': this.requiredWhenGreater,
			'requiredWhenLower': this.requiredWhenLower,
			'validators': this.validators
        }, this.options)
	}
}

export {
	Type
}