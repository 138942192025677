import sprintf from 'sprintf-js'

class ErrorMessage
{
	static messages = [];
	static lang = 'en';

	static setMessages(language, messages = {})
	{
		if (this.messages[language] == undefined) {
			this.messages[language] = {};
        }
		this.messages[language] = Object.assign({}, this.messages[language], messages);
	}

	static language(language)
	{
		this.lang = language;
	}

	static translate(name, args = [], value = '')
	{
		if (this.messages[this.lang][name] == undefined) {
			return [this.lang, name].join(".");
		}
		return sprintf.vsprintf(this.messages[this.lang][name], args);
	}
}

export {
    ErrorMessage
}