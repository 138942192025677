class Cache {

	static schemas = {}

	static set(schemas)
	{
		this.schemas = schemas
	}

	static get (key) {
		return this.schemas[key]
	}
}

export { Cache }