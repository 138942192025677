import React, {useEffect} from 'react'

import Layout from 'layouts/Main'
import {t, T} from 'components/Translate'

export default function ThankYouUpdatePage() {

    useEffect(() => {
        window.scrollTo(0, 0)
        window.history.replaceState({}, false, '/')
    }, [])

    return (
        <Layout title={t("Dane zapisane", "Details updated")}>
            <main className="main bg-gray">
                    <div className="container">
                        <h2 className="main-title main-title--border-top">{t("Dane zostały zmienione", "Your details has been updated")}</h2>
                        <div className="form__content">
                            <div className="register-info">
                                {t("Twoje dane zostały zaktualizowane.", "Your details has been updated")}
                            </div>
                        </div>
                    </div>
            </main>
        </Layout>
    )
}