import {Validator} from './Validator'

class IntegerValidator extends Validator
{
	min(min)
	{
		return this.value >= min;
	}

	max(max)
	{
		return this.value <= max;
	}

	in(values)
	{
		return values.indexOf(this.value) != -1;
	}
}

export {
    IntegerValidator
}