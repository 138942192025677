class ErrorConverter
{
	errors = [];

	constructor(errors)
	{
		this.errors = errors;
	}

	asString()
	{
		return this.errors.shift();
	}

	asDotArray()
	{
		return this.errors;
	}
}

export {
    ErrorConverter
}