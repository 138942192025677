import {Validator} from './Validator'
import validateEmail from 'filter-validate-email'

const strtotime = (date) => Math.round((new Date(date)).getTime() / 1000)

class StringValidator extends Validator
{
	min(min)
	{
		return this.value.length >= min;
	}

	max(max)
	{
		return this.value.length <= max;
	}

	date()
	{
		return strtotime(this.value) > 0;
	}

	in()
	{
        return Object.values(arguments).indexOf(this.value) != -1;
	}

	betweenDates(min, max)
	{
		return (strtotime(this.value) >= strtotime(min)) && (strtotime(this.value) <= strtotime(max))
	}

	notEmpty()
	{
		return this.value.length > 0
	}

	email()
	{
		return validateEmail(this.value, false)
	}

	phone()
	{
		return !/[^0-9\+\-\s]/.test(this.value)
	}

    nip()
    {
        if (!/^[0-9]{10}$/.test(this.value)) {
            return false;
        }
     
        let arrSteps = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let intSum = 0;
     
        for (let i = 0; i < 9; i++) {
            intSum += arrSteps[i] * this.value[i];
        }
     
        let int = intSum % 11;
        let intControlNr = int === 10 ? 0 : int;
     
        if (intControlNr == this.value[9]) {
            return true;
        }
     
        return false;
    }

	pesel()
	{
		if (!/^[0-9]{11}$/.test(this.value)) {
			return false;
		}
	 
		let arrSteps = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
		let intSum = 0

		for (let i = 0; i < 10; i++) {
			intSum += arrSteps[i] * this.value[i]
		}

		let int = 10 - intSum % 10;
		let intControlNr = (int == 10) ? 0 : int;
		if (intControlNr == this.value[10]) {
			return true
		}
		return false
	}

	postalCode()
	{
		return /^([0-9]{2})(-[0-9]{3})?$/i.test(this.value)
	}
}

export {
    StringValidator
}