import React, {useEffect} from 'react'

import Layout from 'layouts/Main'
import {Link, useNavigate} from '@reach/router'
import {t, T} from 'components/Translate'

import checkIcon from 'images/icons/check-icon.svg'

export default function Tickets() {

    useEffect(() => window.scrollTo(0, 0), [])

    const navigate = useNavigate();

    return (
        <Layout title="Bilety">
            <main className="main">
                <div className="container">
                    <h2 className="main-title main-title--border-top">{t('Dla uczestnika', 'For Participant')}</h2>
                    <p className="text">
                        <T
                        pl={<><>Open Eyes Economy Summit 5 będzie w tym roku połączeniem klasycznej formuły Kongresu z nowoczesną wirtualną platformą, która zapewni uczestnikom nieograniczony dostęp do wiedzy i networkingu od zawsze stanowiących najważniejsze elementy szczytu.</><br/>Wystąpienia odbędą się – jak co roku – w Centrum Kongresowym ICE Kraków, będziemy je jednak transmitować w czasie rzeczywistym na internetowej platformie. Umożliwi ona także odwiedzanie stanowisk partnerów oraz rozmowy z innymi uczestnikami. Po więcej informacji na temat formuły tegorocznego Kongresu zapraszamy tutaj: <a href="https://oees.pl/oees-5">oees.pl/oees-5</a></>}
                        en={<><>This year, Open Eyes Economy Summit 5 will have a hybrid form of the classic formula of the Congress combined with a modern virtual platform that will provide participants with unlimited access to knowledge and networking, which have always been the most important elements of the summit.</><br/>The speeches will be held - as every year - at the ICE Krakow Congress Centre; however, we will ensure real time streaming via an internet platform. The platform will also allow visiting partners’ stands and discussions with other participants. To get further information on the formula of this year’s Congress, please visit: <a href="https://oees.pl/en/oees-5-2/">oees.pl/en/oees-5-2/</a></>}
                        />
                    </p>
                </div>
                <div className="article-header">
                    <div className="container">
                        <h2 className="main-title">{t('Bilety na OEES 5 online', 'Tickets to OEES5 on-line')}</h2>
                    </div>
                    <div className="article-header__container">
                        <div className="container">
                            <h3 className="article-header__title">{t('AKTUALNA OFERTA', 'CURRENT OFFER')}</h3>
                        </div>
                    </div>
                </div>
                <div className="article-content">
                    <div className="container">
                        <div className="tile-table-container">
                            <h3 className="secondary-title secondary-title--borderBottom">{t('KUP BILET', 'BUY A TICKET')}</h3>
                            <div className="tile-table">
                                <div className="tile-table__row">
                                    <div className="tile-table__col"></div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('I próg: do 31.10.2020', 'Threshold 1: by 31.10.2020')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('II próg: 1.11.2020 – 16.11.2020', 'Threshold 2: 1.11.2020 – 16.11.2020')}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">{t('PEŁNY DOSTĘP', 'FULL ACCESS')}</h4>
                                            <div className="tile-table__title-desc">{t('(płatny)', '(paid option)')}</div>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <a href="#" className="tile-table__link">
                                            <div className="tile-table__el tile-table__el--gray">
                                                <h4 className="tile-table__title">{t('ZAREJESTRUJ SIĘ | 100 zł', 'REGISTER | PLN 100')}</h4>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="tile-table__col">
                                        <Link to="/registration/attendee/paid" className="tile-table__link">
                                            <div className="tile-table__el tile-table__el--blue">
                                                <h4 className="tile-table__title">{t('ZAREJESTRUJ SIĘ | 150 zł', 'REGISTER | PLN 150')}</h4>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">{t('OGRANICZONY DOSTĘP', 'LIMITED ACCESS')}</h4>
                                            <div className="tile-table__title-desc">{t('(bezpłatny)', '(free option)')}</div>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <button type="button"  
                                                className="tile-table__link"
                                                data-toggle="modal" 
                                                data-target="#rejeDarmModal">
                                            <div className="tile-table__el tile-table__el--gray">
                                                <h4 className="tile-table__title">{t('ZAREJESTRUJ SIĘ | 0 zł', 'REGISTER | PLN 0')}</h4>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="tile-table__col">
                                        <button type="button"  
                                                className="tile-table__link"
                                                data-toggle="modal" 
                                                data-target="#rejeDarmModal">
                                            <div className="tile-table__el tile-table__el--blue">
                                                <h4 className="tile-table__title">{t('ZAREJESTRUJ SIĘ | 0 zł', 'REGISTER | PLN 0')}</h4>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>      

                        <div className="tile-table-container">
                            <h3 className="secondary-title secondary-title--borderBottom">{t('CO ZYSKUJESZ?', 'WHAT YOU GAIN?')}</h3>
                            <div className="tile-table">
                                <div className="tile-table__row">
                                    <div className="tile-table__col" style={{alignSelf: 'flex-end'}}>
                                        <h3 className="secondary-title">{t('AGENDA', 'AGENDA')}</h3>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">{t('PEŁNY DOSTĘP', 'FULL ACCESS')}</h4>
                                            <div className="tile-table__title-desc">{t('(płatny)', '(paid option)')}</div>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">{t('OGRANICZONY DOSTĘP', 'LIMITED ACCESS')}</h4>
                                            <div className="tile-table__title-desc">{t('(bezpłatny)', '(free option)')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Streaming live', 'Live streaming')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">{t('100% - oglądasz, co chcesz', '100% - watch whatever you want')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">{t('10% - sesje udostępnione przez organizatora', '10% - sessions selected by the organiser')}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Sesje Q&A ze spekerami', 'Q&A sessions with speakers')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">
                                                <img src={checkIcon} alt="" />
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Czat na żywo', 'Live chat')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">
                                                <img src={checkIcon} alt="" />
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Dostęp do materiałów po Kongresie', 'Access to the materials after the congress')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <h4 className="tile-table__title">
                                                <img src={checkIcon} alt="" />
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tile-table-container">
                            <h3 className="secondary-title">{t('STREFA EXPO', 'EXPO ZONE')}</h3>
                            <div className="tile-table">
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Transmisja ze stoisk', 'Broadcast from stands')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Materiały partnerów', 'Partners’ materials')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tile-table-container">
                            <h3 className="secondary-title">{t('NETWORKING', 'NETWORKING')}</h3>
                            <div className="tile-table">
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Ogólny czat networkingowy', 'General networking chat')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                            
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Dyskusyjne stoliki tematyczne', 'Thematic discussion tables')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Rozmowy 1:1', '1:1 chats')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Wysyłka Welcome Pack', 'Welcome Pack Shipping')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Elektroniczny welcome pack', 'Digital welcome pack')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                                <div className="tile-table__row">
                                    <div className="tile-table__col">
                                        <div className="tile-table__el">
                                            <h4 className="tile-table__title">{t('Koncert Michała Nagy\'a „Fughetta”', 'Michał Nagy\'s "Fughetta" concert')}</h4>
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--bordered">
                                            <img src={checkIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="tile-table__col">
                                        <div className="tile-table__el tile-table__el--gray"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="article-header">
                    <div className="container">
                        <h2 className="main-title">{t('Bilety na OEES 5 w ICE Kraków', 'Tickets to OEES 5 in ICE Krakow')}</h2>
                    </div>
                    <div className="article-header__container">
                        <div className="container">
                            <h3 className="article-header__title">{t('OFERTA NIEDOSTĘPNA', 'OFFER UNAVAILABLE')}</h3>
                            <p className="article-header__desc">{t('Decyzja dotycząca sprzedaży biletów na Kongres w formie stacjonarnej została odwołana.', 'The decision to sell tickets to the Congress in a stationary form has been canceled.')}</p>
                        </div>
                    </div>
                </div>

            </main>
            <div className="modal fade" id="rejeDarmModal" tabindex="-1" aria-labelledby="rejeDarmModalLabel" aria-hidden="true">
                <div className="modal-dialog modal__dialog">
                    <div className="modal-content modal__content"> 
                        <div className="modal-body modal__body">
                            <h3 className="modal__title">{t('Bezpłatna rejestracja', 'Free registration')}</h3>
                            <p className="modal__text">{t('Przy bezpłatnej rejestracji otrzymujesz dostęp jedynie do wybranej przez organizatorów części programu. Są to 4 sesje plenarne, które stanowią ok. 10% wszystkich wystąpień. Nie będziesz mógł także korzystać ze wszystkich funkcji platformy. Czy chcesz kontynuować rejestrację?', 'With free registration, you will have access to 10% of the programme only and you cannot use all the functions of the platform. Do you want to continue registration?')}</p>
                        </div>
                        <div className="modal-footer modal__footer">
                            <button type="button" className="primary-button primary-button--sm primary-button--outlined" data-dismiss="modal">{t('Nie', 'No')}</button>
                            <button data-dismiss="modal" onClick={() => window.location.href="https://kongres.oees.pl/rejestracja"} className="primary-button primary-button--sm">{t('Tak', 'Yes')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}