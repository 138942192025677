import {Validator} from './Validator'

class BooleanValidator extends Validator
{
	positive()
	{
		return this.value !== true;
	}

	negative()
	{
		return this.value !== false;
	}
}

export {
    BooleanValidator
}