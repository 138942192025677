import React, {useEffect} from 'react'

import Layout from 'layouts/Main'
import {Link} from '@reach/router'

import {t, T} from 'components/Translate'

export default function Registration() {

    useEffect(() => window.scrollTo(0, 0), [])
    

    return (
        <Layout title={t('Rejestracja', 'Registration')}>
            
            {/* <T pl={<div class="orange-label">
                <Link to="/welcome_pack">WELCOME PACK OEES 5</Link>
            </div>} en={<span></span>} /> */}

            <main className="main">
                <div className="container">
                    <h2 className="main-title main-title--border-top">
                        {t('Zarejestruj się jako', 'Register as')}
                    </h2>
                    <div className="register-option">
                        <Link onClick={e => e.preventDefault()} to="/registration/attendee" to="/" className="register-option__el disabled">
                            {t('UCZESTNIK', 'PARTICIPANT')}
                        </Link>
                        <Link onClick={e => e.preventDefault()} to="/registration/student" to="/" className="register-option__el register-option__el--student disabled">
                            {t('STUDENT', 'STUDENT')}
                        </Link>
                    </div>

                    <h3 className="secondary-title">{t('AKREDYTACJE', 'ACCREDIATIONS')}</h3>
                    <div className="separator"></div>
                    <div className="row">
                        <div className="col-md-4">
                            <Link onClick={e => e.preventDefault()} to="/registration/media" to="/" className="akredytacja disabled">{t('MEDIA', 'MEDIA')}</Link>
                        </div>
                        <div className="col-md-4">
                            <Link onClick={e => e.preventDefault()} to="/registration/partner" to="/" className="akredytacja disabled">{t('PARTNER', 'PARTNER')}</Link>
                        </div>
                        <div className="col-md-4">
                            <Link onClick={e => e.preventDefault()} to="/registration/volunteer" to="/" className="akredytacja disabled">{t('WOLONTARIUSZ', 'VOLUNTEER')}</Link>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}