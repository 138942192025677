import React, {useState, useEffect} from 'react'

import Layout from 'layouts/Main'
import {Link, navigate} from '@reach/router'

import closeIcon from 'images/icons/close-icon.png'
import addIcon from 'images/icons/add-icon.png'

import { FieldArray } from 'react-final-form-arrays'
import {Form, Field, ErrorComponent} from 'components/Form'
import { setErrors } from "final-form-set-errors-mutator"
import { format } from 'path'
import {t, T} from 'components/Translate'

export default function PartnerRegistrationForm({data}) {

    useEffect(() => window.scrollTo(0, 0), [])

    const onSubmit = (values) => fetch(`${process.env.REACT_APP_API_URL}/${data ? 'update':'register'}.php?lang=${process.env.REACT_APP_LANG}`, {
        method: 'POST',
        body: JSON.stringify(values)
      })
    
      const onSuccess = (resp) => {
        //login(resp.data.token)

        navigate(data ? '/thank_you_2' : '/thank_you/' + resp.data.type, {noThrow: true})
      }

      const onError = (errors) => {
          var final = false;
          Object.keys(errors).map((value, index) => {
            if (final) return;

            if (value.startsWith('osoby.')) {
                window.x.edit('osoby['+(value.split(".")[1])+']')
                final = true;
            }
          })
      }

      const _mutators = {
          confirm: (args, state, utils) => {
              utils.changeValue(state, args[0]+".edited", () => false)
          },
          edit: (args, state, utils) => {
            utils.changeValue(state, args[0]+".edited", () => true)
        }
      }

    return (
        <Layout title={t("Formularz rejestracyjny", "Registration questionaire")}>
            <main className="main bg-gray">  
            <Form mutators={{..._mutators, setErrors}} initial={data ? data : {osoby: []}} onError={onError} onSuccess={onSuccess} onSubmit={onSubmit} className="form">
                {({ submitting, pristine, values, change, mutators }) => (
                    <>
                    <Field name="type" type="hidden" defaultValue="partner" />
                    {(() => {window.x = mutators})()}
                    <div className="container">
                        <h2 className="main-title main-title--border-top">{t("Dla partnera", "For partner")}</h2>
                        <div className="form__content">
                            <div className="row">
                                <div className="col-md-6 form__el">
                                    <Field type="text" name="imie" placeholder={t("Imię*", "Name*")} />
                                    <ErrorComponent name="imie" />
                                </div>
                                <div className="col-md-6 form__el">
                                    <Field type="text" name="nazwisko" placeholder={t("Nazwisko*", "Surname*")} />
                                    <ErrorComponent name="nazwisko" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 form__el">
                                    <Field type="text" name="adres_email" placeholder={t("Adres e-mail*", "E-mail*")} />
                                    <ErrorComponent name="adres_email" />
                                </div>
                                <div className="col-md-6 form__el">
                                    <Field type="text" name="numer_telefonu" placeholder={t("Numer telefonu*", "Phone number*")} />
                                    <ErrorComponent name="numer_telefonu" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 form__el">
                                    <Field type="text" name="stanowisko" placeholder={t("Stanowisko", "Position")} />
                                    <ErrorComponent name="stanowisko" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 form__el">
                                    <Field type="text" name="nazwa_instytucji" placeholder={t("Nazwa instytucji*", "Entity name*")} />
                                    <ErrorComponent name="nazwa_instytucji" />
                                </div>
                            </div>
                            <FieldArray name="osoby">
                            {({ fields }) => (
                                <div>
                                {fields.map((name, index) => (
                                    <div key={name}>
                                    <div className="form__header">
                                        <h3 className="secondary-title secondary-title--form">{index+2}. {(fields.value[index].imie+fields.value[index].nazwisko).length ? fields.value[index].imie + " " + fields.value[index].nazwisko : t('Osoba', 'Person')}</h3>
                                        <div className="form__header-right">
                                            {!fields.value.filter(x => x.edited).length && <button type="button" className="btn-reset" onClick={() => mutators.edit(name)}>{t('popraw dane', 'edit')}</button> }
                                            {!fields.value.filter(x => x.edited).length && <button type="button" className="btn-reset" onClick={() => fields.remove(index)}>
                                                <img src={closeIcon} alt="" />
                                            </button>}
                                        </div>
                                    </div>
                                    <div className="form__person" style={{display: fields.value[index].edited ? 'block' : 'none'}}>
                                        <Field name="id" type="hidden" defaultValue={fields.value[index].id !== undefined ? fields.value[index].id : null} />
                                        <div className="row">
                                            <div className="col-md-6 form__el">
                                                <Field type="text" name={`${name}.imie`} placeholder={t("Imię*", "Name*")} autoFocus />
                                                <ErrorComponent name={`${name}.imie`} />
                                            </div>
                                            <div className="col-md-6 form__el">
                                                <Field type="text" name={`${name}.nazwisko`} placeholder={t("Nazwisko*", "Surname*")} />
                                                <ErrorComponent name={`${name}.nazwisko`} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form__el">
                                                <Field type="text" name={`${name}.adres_email`} placeholder={t("Adres e-mail*", "E-mail*")} />
                                                <ErrorComponent name={`${name}.adres_email`} />
                                            </div>
                                            <div className="col-md-6 form__el">
                                                <Field type="text" name={`${name}.numer_telefonu`} placeholder={t("Numer telefonu*", "Phone number*")} />
                                                <ErrorComponent name={`${name}.numer_telefonu`} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 form__el">
                                                <Field type="text" name={`${name}.stanowisko`} placeholder={t("Stanowisko", "Position")} />
                                                <ErrorComponent name={`${name}.stanowisko`} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 form__el">
                                                <Field type="text" name={`${name}.nazwa_instytucji`} placeholder={t("Nazwa instytucji*", "Entity name*")} />
                                                <ErrorComponent name={`${name}.nazwa_instytucji`} />
                                            </div>
                                        </div>
                                        <div className="btn-center">
                                            <button type="button" className="primary-button" onClick={() => {
                                                mutators.confirm(name)
                                            }}>{t("Zatwierdź", "Save")}</button>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                    <div className="row">
                                        <div className="col-12">
                                            <button disabled={fields.value.filter(x => x.edited).length || values.osoby.length == 9} type="button" className="btn-addPerson" onClick={() => {
                                                fields.push({ imie: '', nazwisko: '', adres_email: '', numer_telefonu: '', stanowisko: '', nazwa_instytucji: '', id: null, edited: true })
                                            }}>
                                                <img src={addIcon} alt="" /> {t("Dodaj kolejną osobę", "Add next person")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            </FieldArray>

                            {!data && (
                            <div className="row form__discount-code">
                                <div className="col-md-6 col-lg-4">
                                    <Field type="text" name="kod_dostepu" placeholder={t("Kod dostępu", "Access code")} />
                                    <ErrorComponent name="kod_dostepu" />
                                </div>
                            </div>)}
                        </div>

                        <div className="form__info">
                            <p>* {t("pola obowiązkowe", "Obligatory fields")}</p>
                        </div>
                    </div>
                
                    <div className="container">
                        <div className="form__option">
                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="regulamin" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t(<>Oświadczam, że zapoznałem/am się z treścią <a href="https://oees.pl/wp-content/uploads/2020/09/Regulamin-Kongres-Open-Eyes-Economy-Summit-5-2.pdf" target="_blank">Regulaminu Kongresu Open Eyes Economy Summit</a> i akceptuję zawarte w nim warunki.</>, <>I declare that I have read the <a href="https://oees.pl/wp-content/uploads/2020/09/Regulamin-OEES-5-EN.pdf" target="_blank">Regulations of the Open Eyes Economy Summit</a> and I accept the terms and conditions contained therein</>)}</p>
                                    <ErrorComponent name="regulamin" />
                                </div>
                            </div>

                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="polityka_prywatnosci" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t(<>Oświadczam, że zapoznałem/am się i akceptuję <a href="https://oees.pl/wp-content/uploads/2020/09/Polityka-Prywatnosci.PL_.pdf" target="_blank">Politykę Prywatności</a> Kongresu Open Eyes Economy Summit</>, <>I declare that I have read and accept the <a href="https://oees.pl/wp-content/uploads/2020/09/Privacy-Policy-2020.pdf" target="_blank">Privacy Policy</a> of the Open Eyes Economy Summit</>)}.</p>
                                    <ErrorComponent name="polityka_prywatnosci" />
                                </div>
                            </div>
                            
                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="przetwarzanie_danych" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t("Oświadczam, że wyrażam zgodę na przetwarzanie przez Administratora Fundację Gospodarki i Administracji Publicznej z siedzibą w Krakowie (KRS 0000232184) moich danych osobowych w celu rejestracji mojego uczestnictwa w „Kongresie Open Eyes Economy”, na warunkach określonych w Polityce Prywatności zamieszczonej na stronie www.oees.pl", "I declare that I consent to the processing by the Administrator of the Foundation for Economy and Public Administration based in Krakow (KRS 0000232184) of my personal data for the purpose of registration of my participation in the “Open Eyes Economy Summit” under the conditions specified in the Privacy Policy available at www.oees.pl")}.</p>
                                    <ErrorComponent name="przetwarzanie_danych" />
                                </div>
                            </div>

                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="marketing" defaultChecked={data ? data.marketing : false} />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text"><strong>{t("Zgoda opcjonalna", "Optional consent")}</strong></span>
                                </label>
                                <div class="consent-text consent-text--opcjonalna">
                                    <p>{t("Oświadczam, że wyrażam zgodę na przetwarzanie przez Administratora Fundację Gospodarki i Administracji Publicznej z siedzibą w Krakowie (KRS 0000232184) moich danych osobowych obejmujących adres e-mail w celach marketingowych związanych z organizacją „Kongresu Open Eyes Economy”, na warunkach określonych w Polityce Prywatności zamieszczonej na stronie www.oees.pl", "I declare that I consent to the processing by the Administrator of the Foundation for Economy and Public Administration based in Krakow (KRS 0000232184) of my personal data including e-mail address for marketing purposes under the conditions specified in the Privacy Policy available at www.oees.pl")}.</p>
                                    <ErrorComponent name="marketing" />
                                </div>
                            </div>
                        </div>

                        <FieldArray name="osoby">
                        {({fields}) =>(<div className="btn-center">
                            <button type="submit" className="primary-button" disabled={fields.value.filter(x => x.edited).length || submitting}>
                                {data ? t('Aktualizuj dane', 'Update details') : t('Zarejestruj się', 'Register')}
                            </button>
                        </div>)}
                        </FieldArray>
                    </div>
                    </>)}
                </Form>
            </main>
        </Layout>
    )
}