
import './vendor/bootstrap-4.5.2-dist/css/bootstrap.min.css'
import './css/main.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from "@reach/router"

import Registration from './Registration'
import Attendee from './Attendee'
import Student from './Student'
import Media from './Media'
import Partner from './Partner'
import Volunteer from './Volunteer'
import Tickets from './Tickets'
import Prelegent from './Prelegent'
import ThankYou from './ThankYou'
import ThankYou2 from './ThankYou2'
import Hash from './Hash'
import AfterPack from './AfterPack'

window.dataLayer = window.dataLayer || [];
window.gtag = function() {
  window.dataLayer.push(arguments);
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Registration path="/" />
      <Tickets path="/registration/attendee" />
      <Attendee path="/registration/attendee/:type" />
      <Student path="/registration/student" />
      <Media path="/registration/media" />
      <Partner path="/registration/partner" />
      <Volunteer path="/registration/volunteer" />
      <Prelegent path="/registration/prelegent" />
      <ThankYou path="/thank_you/:type" />
      <ThankYou2 path="/thank_you_2" />
      {/* <AfterPack path="/polskaekologia" /> */}
      <Hash path="/:hash" />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)