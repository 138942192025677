import {Validator} from './Validator'

class ObjectValidator extends Validator
{
	only()
	{
		// Check if object has only keys which have been declared in schema...
		return false;
	}
}

export {
    ObjectValidator
}