import React, {useEffect} from 'react'

import Layout from 'layouts/Main'
import {Link, navigate} from '@reach/router'

import {Form, Field, ErrorComponent} from 'components/Form'
import {t, T} from 'components/Translate'

import allINUJ from 'images/AllInUJ_Logo_Hor_Color.png'
import aegee from 'images/Logo_AEGEE-Krakow_blue_medium.png'
import akbiuro from 'images/AISEC.png'
import bestagh from 'images/logo_BEST_kolor_transparent.png'
import sknsz from 'images/sknsz.png'
import pragma from 'images/pragma.png'
import knro from 'images/knro.png'
import mlodzi from 'images/mlodzi.jpg'

export default function StudentRegistrationForm({data}) {

    useEffect(() => window.scrollTo(0, 0), [])

    const onSubmit = (values) => fetch(`${process.env.REACT_APP_API_URL}/${data ? 'update':'register'}.php?lang=${process.env.REACT_APP_LANG}`, {
        method: 'POST',
        body: JSON.stringify(values)
      })
    
      const onSuccess = (resp) => {
        //login(resp.data.token)
        navigate(data ? '/thank_you_2' : '/thank_you/' + resp.data.type, {noThrow: true})
      }

    return (
        <Layout title={t("Bilety", "Tickets")}>
            <main className="main bg-gray">
                <div className="container">
                    <h2 className="main-title main-title--border-top">Dla studentów</h2>
                    <p className="text">
                        Jesteś studentem? Aby otrzymać darmową wejściówkę na OEES weź udział w konkursie organizowanym przez jedną z naszych partnerskich organizacji!
                    </p>
                    <div className="tile-student-container">
                        <p className="text">Kliknij i sprawdź szczegóły:</p>
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <a href="https://www.facebook.com/AllInUJ/posts/3420873361336038" target="_blank" className="tile-student">
                                    <img src={allINUJ} alt="" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="https://www.facebook.com/aegee.krakow/posts/4516201038450402" target="_blank" className="tile-student">
                                    <img src={aegee} alt="" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="https://www.facebook.com/akbiurokarierup/photos/a.223654801130252/1692592494236468/" target="_blank" className="tile-student">
                                    <img src={akbiuro} alt="" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="https://www.facebook.com/BEST.AGH.Krakow/posts/10151453811509944" target="_blank" className="tile-student">
                                    <img src={bestagh} alt="" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="#" className="tile-student">
                                    <img src={sknsz} alt="" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="https://www.facebook.com/KNP.PRAGMA/posts/3707147432669395" target="_blank" className="tile-student">
                                    <img src={pragma} alt="" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="https://www.facebook.com/KoloNaukoweRozwojuOsobistego/photos/a.296528453735269/3672547699466644/" target="_blank" className="tile-student">
                                    <img src={knro} alt="" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <a href="https://www.facebook.com/Mlodzi.w.Lodzi/posts/3416246911787114" target="_blank" className="tile-student">
                                    <img src={mlodzi} alt="" />
                                </a>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </main>
        </Layout>
    )
}