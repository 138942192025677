import React, { useEffect } from 'react'

import Layout from 'layouts/Main'
import {Link, navigate} from '@reach/router'

import {Form, Field, ErrorComponent} from 'components/Form'
import {t, T} from 'components/Translate'

export default function AttendeeRegistrationForm({type, data = false}) {

    useEffect(() => window.scrollTo(0, 0), [])

    const onSubmit = (values) => fetch(`${process.env.REACT_APP_API_URL}/${data ? 'update':'register'}.php?lang=${process.env.REACT_APP_LANG}`, {
        method: 'POST',
        body: JSON.stringify(values)
      })
    
      const onSuccess = (resp) => {
        if (resp.data.link !== undefined) {
            window.location.href = resp.data.link
        } else {
            navigate(data ? '/thank_you_2' : '/thank_you/' + resp.data.type, {noThrow: true})
        }
      }

    if (['free', 'paid'].indexOf(type) == -1 && !data) {
        return null;
    }

    return (
        <Layout title={t("Formularz rejestracyjny", "Registration questionaire")}>
            <main className="main bg-gray">
            <Form initial={data ? data : {info_ice: "Tak", attendee_type: type}} onSuccess={onSuccess} onSubmit={onSubmit} className="form">
                {({ submitting, pristine, values, change }) => (
                    <>
                    <Field name="type" type="hidden" defaultValue="attendee" />
                    <div className="container">
                        <h2 className="main-title main-title--border-top">{t('Dla uczestnika', 'For a participant')}</h2>
                        <div className="form__content">
                            <div className="row">
                                <div className="col-md-6 form__el">
                                    <Field name="imie" type="text" placeholder={t("Imię*", "Name*")} />
                                    <ErrorComponent name="imie" />
                                </div>
                                <div className="col-md-6 form__el">
                                    <Field name="nazwisko" type="text" placeholder={t("Nazwisko*", "Surname*")} />
                                    <ErrorComponent name="nazwisko" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 form__el">
                                    <Field name="adres_email" type="text" placeholder={t("Adres e-mail*", "E-mail*")} />
                                    <ErrorComponent name="adres_email" />
                                </div>
                                <div className="col-md-6 form__el">
                                    <Field name="numer_telefonu" type="text" placeholder={t("Numer telefonu*", "Phone number*")} />
                                    <ErrorComponent name="numer_telefonu" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 form__el">
                                    <Field name="stanowisko" type="text" placeholder={t("Stanowisko", "Position")} />
                                    <ErrorComponent name="stanowisko" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 form__el">
                                    <Field name="nazwa_instytucji" type="text" placeholder={t("Nazwa instytucji*", "Entity name*")} />
                                    <ErrorComponent name="nazwa_instytucji" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr/>
                    <div className="container">
                        <div className="form__option">
                            <h4 className="form__title">{t("Dodaj bilet na wydarzenie artystyczne", "Add a ticket to artistic events")}:</h4>
                            <label className="checkbox">
                                <Field type="checkbox" name="koncert" defaultChecked={data ? data.koncert : false} />
                                <span className="checkbox__checkmark"></span>
                                <span className="checkbox__text"><strong>{t("KONCERT HIRUNDO MARIS 20 zł", "HIRUNDO MARIS CONCERT 20 PLN")}</strong></span>
                            </label>
                            <ErrorComponent name="koncert" />
                        </div>
                    </div>
                    <hr/>
                    <div className="container">
                        <div className="form__option">
                            <h4 className="form__title">{t("Chcę otrzymać informację o możliwości udziału w Kongresie w Centrum Kongresowym ICE Kraków", "I would like to receive information on the opportunity to participate in the Summit in the ICE Krakow Congress Centre")}*</h4>
                            <div className="row">
                                <div className="col-md-2 col-lg-1 form__el">
                                    <label className="checkbox">
                                        <Field type="radio" name="info_ice" value="Tak" defaultChecked={data ? data.info_ice == "Tak" : true} />
                                        <span className="checkbox__checkmark checkbox__checkmark--radio"></span>
                                        <span className="checkbox__text">{t("Tak", "Yes")}</span>
                                    </label>
                                </div>
                                <div className="col-md-2 col-lg-1">
                                    <label className="checkbox">
                                        <Field type="radio" name="info_ice" value="Nie" defaultChecked={data ? data.info_ice == "Nie" : false} />
                                        <span className="checkbox__checkmark checkbox__checkmark--radio"></span>
                                        <span className="checkbox__text">{t("Nie", "No")}</span>
                                    </label>
                                </div>
                            </div>
                            <ErrorComponent name="info_ice" />
                        </div>
                    </div> */}
                    <hr />
                    <div className="container">
                        <div className="form__option">
                            <h4 className="form__title">{t("Skąd dowiedziałeś się o Kongresie", "Where did you learn about the Congress from?")}?</h4>
                            <div className="row">
                                <div className="col-md-3 col-lg-2 form__el">
                                    <label className="checkbox">
                                        <Field name="zrodlo[]" defaultChecked={data ? data.zrodlo.indexOf("Social media") != -1 : false} value="Social media" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">Social media</span>
                                    </label>
                                </div>
                                <div className="col-md-3 form__el">
                                    <label className="checkbox">
                                        <Field name="zrodlo[]" defaultChecked={data ? data.zrodlo.indexOf("Reklama online") != -1 : false} value="Reklama online" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Reklama online", "On-line advertisement")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-lg-4 form__el">
                                    <label className="checkbox">
                                        <Field name="zrodlo[]" defaultChecked={data ? data.zrodlo.indexOf("Media; portale internetowe; radio; prasa") != -1 : false} value="Media; portale internetowe; radio; prasa" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Media: portale internetowe, radio, prasa", "Media: internet websites, radio, press")}</span>
                                    </label>
                                </div>
                                <div className="col-md-5 form__el">
                                    <label className="checkbox">
                                        <Field name="zrodlo[]" defaultChecked={data ? data.zrodlo.indexOf("Ulotka") != -1 : false} value="Ulotka" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Ulotka", "Leaflet")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-lg-3 form__el">
                                    <label className="checkbox">
                                        <Field name="zrodlo[]" defaultChecked={data ? data.zrodlo.indexOf("Od znajomego/znajomej") != -1 : false} value="Od znajomego/znajomej" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Od znajomego/znajomej", "From a friend")}</span>
                                    </label>
                                </div>
                                <div className="col-md-6 form__el">
                                    <label className="checkbox">
                                        <Field name="zrodlo[]" defaultChecked={data ? data.zrodlo.indexOf("Uczestniczyłem/am w poprzednich edycjach") != -1 : false} value="Uczestniczyłem/am w poprzednich edycjach" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Uczestniczyłem/am w poprzednich edycjach", "I have participated in previous editions")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="checkbox">
                                        <Field name="zrodlo[]" defaultChecked={data ? data.zrodlo.indexOf("Inne") != -1 : false} value="Inne" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Inne", "Other")}</span>
                                    </label>

                                    {values.zrodlo && values.zrodlo.indexOf("Inne") != -1 && (
                                        <div className="form__option">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <Field autoFocus name="zrodlo_inne" type="text" placeholder={t("Wpisz tutaj", "Type here")} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <ErrorComponent name="zrodlo" />
                        </div>
                        <div className="form__option">
                            <h4 className="form__title">{t("Co skłoniło Cię do udziału w Kongresie", "What made you participate in the Summit?")}?</h4>
                            <div className="row">
                                <div className="col-md-4 col-lg-3 form__el">
                                    <label className="checkbox">
                                        <Field name="dlaczego_oees[]" defaultChecked={data ? data.dlaczego_oees.indexOf("Wartość merytoryczna") != -1 : false} value="Wartość merytoryczna" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Wartość merytoryczna", "Substantive value")}</span>
                                    </label>
                                </div>
                                <div className="col-md-4 form__el">
                                    <label className="checkbox">
                                        <Field name="dlaczego_oees[]" defaultChecked={data ? data.dlaczego_oees.indexOf("Wartość networkingowa") != -1 : false} value="Wartość networkingowa" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Wartość networkingowa", "Networking value")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-lg-3 form__el">
                                    <label className="checkbox">
                                        <Field name="dlaczego_oees[]" defaultChecked={data ? data.dlaczego_oees.indexOf("Atmosfera") != -1 : false} value="Atmosfera" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Atmosfera", "Ambience")}</span>
                                    </label>
                                </div>
                                <div className="col-md-6 form__el">
                                    <label className="checkbox">
                                        <Field name="dlaczego_oees[]" defaultChecked={data ? data.dlaczego_oees.indexOf("Zainteresowanie ekonomią wartości") != -1 : false} value="Zainteresowanie ekonomią wartości" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Zainteresowanie ekonomią wartości", "Interest in the economy of values")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-lg-3 form__el">
                                    <label className="checkbox">
                                        <Field name="dlaczego_oees[]" defaultChecked={data ? data.dlaczego_oees.indexOf("Innowacyjna formuła") != -1 : false} value="Innowacyjna formuła" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Innowacyjna formuła", "Innovative formula")}</span>
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <label className="checkbox">
                                        <Field name="dlaczego_oees[]" defaultChecked={data ? data.dlaczego_oees.indexOf("Inne") != -1 : false} value="Inne" type="checkbox" />
                                        <span className="checkbox__checkmark"></span>
                                        <span className="checkbox__text">{t("Inne", "Other")}</span>
                                    </label>

                                    {values.dlaczego_oees && values.dlaczego_oees.indexOf("Inne") != -1 && (
                                        <div className="form__option">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12">
                                                    <Field autoFocus name="dlaczego_oees_inne" type="text" placeholder={t("Wpisz tutaj", "Type here")} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <ErrorComponent name="dlaczego_oees" />
                        </div>
                    </div>
                    <hr/>
                    <div className="container">

                        {!data && type == 'paid' && (
                        <div className="form__option">
                            <div className="row">
                                <div className="col-md-6 col-lg-4">
                                    <Field name="kod_rabatowy" type="text" placeholder={t("Kod rabatowy", "Rebate code")} />
                                    <ErrorComponent name="kod_rabatowy" />
                                </div>
                            </div>
                            <div className="form__info">
                                <p>* {t("pola obowiązkowe", "Obligatory fields")}</p>
                            </div>
                        </div>)}
                        
                        <div className="form__option">
                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="regulamin" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t(<>Oświadczam, że zapoznałem/am się z treścią <a href="https://oees.pl/wp-content/uploads/2020/09/Regulamin-Kongres-Open-Eyes-Economy-Summit-5-2.pdf" target="_blank">Regulaminu Kongresu Open Eyes Economy Summit</a> i akceptuję zawarte w nim warunki.</>, <>I declare that I have read the <a href="https://oees.pl/wp-content/uploads/2020/09/Regulamin-OEES-5-EN.pdf" target="_blank">Regulations of the Open Eyes Economy Summit</a> and I accept the terms and conditions contained therein</>)}</p>
                                    <ErrorComponent name="regulamin" />
                                </div>
                            </div>

                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="polityka_prywatnosci" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t(<>Oświadczam, że zapoznałem/am się i akceptuję <a href="https://oees.pl/wp-content/uploads/2020/09/Polityka-Prywatnosci.PL_.pdf" target="_blank">Politykę Prywatności</a> Kongresu Open Eyes Economy Summit</>, <>I declare that I have read and accept the <a href="https://oees.pl/wp-content/uploads/2020/09/Privacy-Policy-2020.pdf" target="_blank">Privacy Policy</a> of the Open Eyes Economy Summit</>)}.</p>
                                    <ErrorComponent name="polityka_prywatnosci" />
                                </div>
                            </div>
                            
                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="przetwarzanie_danych" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t("Oświadczam, że wyrażam zgodę na przetwarzanie przez Administratora Fundację Gospodarki i Administracji Publicznej z siedzibą w Krakowie (KRS 0000232184) moich danych osobowych w celu rejestracji mojego uczestnictwa w „Kongresie Open Eyes Economy”, na warunkach określonych w Polityce Prywatności zamieszczonej na stronie www.oees.pl", "I declare that I consent to the processing by the Administrator of the Foundation for Economy and Public Administration based in Krakow (KRS 0000232184) of my personal data for the purpose of registration of my participation in the “Open Eyes Economy Summit” under the conditions specified in the Privacy Policy available at www.oees.pl")}.</p>
                                    <ErrorComponent name="przetwarzanie_danych" />
                                </div>
                            </div>

                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="marketing" defaultChecked={data ? data.marketing : false} />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text"><strong>{t("Zgoda opcjonalna", "Optional consent")}</strong></span>
                                </label>
                                <div class="consent-text consent-text--opcjonalna">
                                    <p>{t("Oświadczam, że wyrażam zgodę na przetwarzanie przez Administratora Fundację Gospodarki i Administracji Publicznej z siedzibą w Krakowie (KRS 0000232184) moich danych osobowych obejmujących adres e-mail w celach marketingowych związanych z organizacją „Kongresu Open Eyes Economy”, na warunkach określonych w Polityce Prywatności zamieszczonej na stronie www.oees.pl", "I declare that I consent to the processing by the Administrator of the Foundation for Economy and Public Administration based in Krakow (KRS 0000232184) of my personal data including e-mail address for marketing purposes under the conditions specified in the Privacy Policy available at www.oees.pl")}.</p>
                                    <ErrorComponent name="marketing" />
                                </div>
                            </div>
                        </div>

                        <div className="btn-center">
                            <button type="submit" className="primary-button" disabled={submitting}>
                                {type=='free' && !values.koncert ? t("Zarejestruj się", "Register") : t("Przejdź do płatności", "Pay now")}
                            </button>
                        </div>
                    </div>
                    </>)}
                </Form>

            </main>
        </Layout>
    )
}