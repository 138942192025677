import React, { useEffect } from 'react'

import {Link} from '@reach/router'

import logo from 'images/logo.svg'
import logoWhite from 'images/logo-white.svg'
import fbIcon from 'images/icons/fb-icon.svg'
import twIcon from 'images/icons/tw-icon.svg'
import igIcon from 'images/icons/instagram-icon.svg'
import ytIcon from 'images/icons/yt-icon.svg'
import liIcon from 'images/icons/linkedin-icon.svg'

import {t, T} from 'components/Translate'

export default function Main({children, title}) {

    useEffect(() => {
        document.title = title
        window.gtag('config', 'UA-72960378-1', {
            'page_path': window.location.pathname
        })
        window.gtag('js', new Date())
        return () => {}
    })

    return (
        <>
            <header className="page-header">
                <div className="container page-header__container">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="page-header__right">
                        <div className="page-title">
                            <h3 className="page-title__text">{title}</h3>
                            <div className="page-title__line"></div>
                        </div>
                    </div>
                </div>
            </header>

            {children}

            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-1">
            
                        </div>
                        <div className="col-md-11">
                            <hr/>
                            <div className="pull-right">
                                <a href="#" onClick={() => window.scrollTo(0, 0)}>
                                    <div id="iconup"><img src="https://oees.pl/wp-content/themes/wordpressify/img/footericonup.png" alt="" /></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-1">
                            <img src={logoWhite} alt="" />
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <h5>Social media</h5>
                            <a href="https://www.facebook.com/OpenEyesEconomy/" className="social"><img src={fbIcon} alt="" /></a>
                            <a href="https://twitter.com/openeyeseconomy" className="social"><img src={twIcon} alt="" /></a>
                            <br/>
                            <a href="https://www.instagram.com/openeyeseconomy/" className="social"><img src={igIcon} alt="" /></a>
                            <a href="https://www.youtube.com/channel/UCeJr22S3_OlwbZhTeFJoxdg/featured?disable_polymer=1" className="social"><img src={ytIcon} alt="" /></a>
                            <br/>
                            <a href="https://pl.linkedin.com/company/open-eyes-economy" className="social"><img style={{borderRadius: '50%'}} src={liIcon} alt="" /></a>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <ul>
                                <li><a href={t('https://oees.pl/czym-jest-kongres/', 'https://oees.pl/en/they-talk-about-us/')}>{t('O Kongresie', 'About Congress')}</a></li>
                                <li><a href={t('https://oees.pl/radaprogramowa/', 'https://oees.pl/radaprogramowa/')}>{t('Rada Programowa', 'Programme Council')}</a></li>
                                <li><a href="https://oees.pl/video/">Video</a></li>
                                <li><a href={"https://oees.pl/poprzednie-edycje/", "https://oees.pl/en/previous-editions/"}>{t('Poprzednie edycje', 'Previous editions')}</a></li>
                                <li><a href={"https://oees.pl/galerie/", "https://oees.pl/galerie/"}>{t('Fotorelacje', 'Gallery')}</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <ul>
                                <li><a href={t('https://oees.pl/wspolpraca/', 'https://oees.pl/en/cooperation')}>{t('Współpraca – dla biznesu', 'Cooperation')}</a></li>
                                <li><a href={t('https://oees.pl/dla-mediow/', 'https://oeespl/dla-mediow')}>{t('Dla mediów', 'Media')}</a></li>
                                <li><a href={t('https://oees.pl/dobrzewiedziec/', 'https://oees.pl/dobrzewiedziec/')}>{t('Dobrze wiedzieć', 'Good to know')}</a></li>
                                <li><a href={t('https://oees.pl/wolontariat/', 'https://oees.pl/wolontariat/')}>{t('Wolontariat', 'Volunteers')}</a></li>
                                <li><a href="https://oees.pl/newsletter/">{t('Newsletter', 'Newsletter')}</a></li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <ul>
                                <li><a href={t('https://oees.pl/oees-5/', 'https://oees.pl/en/oees-5-2/')}>{t('OEES 5 – hybryda', 'OEES 5 formula')}</a></li>
                                <li><a href={t('https://oees.pl/wp-content/uploads/2020/07/OEES_2020_przewodnik.pdf', 'https://oees.pl/wp-content/uploads/2020/08/OEES_2020_przewodnik_ver4_EN_www.pdf')}>{t('Przewodnik po OEE', 'OEE GUIDE')}</a></li>
                                <li><a href={t('https://oees.pl/kontakt/', 'https://oees.pl/kontakt/')}>{t('Kontakt', 'Contact')}</a></li>
                                <li><a href={t('http://fundacjagap.pl/wp-content/uploads/2018/10/RODO_Klauzula_informacyjna_strona_internetowa.pdf', 'http://fundacjagap.pl/wp-content/uploads/2018/10/RODO_Klauzula_informacyjna_strona_internetowa.pdf')}>{t('Klauzula RODO', 'Klauzula RODO')}</a></li>
                                <li><a href="https://oees.pl/wp-content/uploads/2019/09/OEES.PL-ZASTRZEŻENIA-PRAWNE.pdf">© 2019 OEES</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}