import {Validator} from './Validator'

class ArrayValidator extends Validator
{
	min(min)
	{
		return this.value.length >= min;
	}

	max(max)
	{
		return this.value.length <= max;
	}
}

export {
    ArrayValidator
}