import React, {useEffect} from 'react'

import Layout from 'layouts/Main'
import {Link, navigate} from '@reach/router'

import {Form, Field, ErrorComponent} from 'components/Form'
import {t, T} from 'components/Translate'

export default function VolunteerRegistrationForm({data}) {

    useEffect(() => window.scrollTo(0, 0), [])

    const onSubmit = (values) => fetch(`${process.env.REACT_APP_API_URL}/${data ? 'update':'register'}.php?lang=${process.env.REACT_APP_LANG}`, {
        method: 'POST',
        body: JSON.stringify(values)
      })
    
      const onSuccess = (resp) => {
        //login(resp.data.token)
        navigate(data ? '/thank_you_2' : '/thank_you/' + resp.data.type, {noThrow: true})
      }

    return (
        <Layout title={t("Formularz rejestracyjny", "Registration questionaire")}>
            <main className="main bg-gray">  
            <Form initial={data ? data : {info_ice: "Tak"}} onSuccess={onSuccess} onSubmit={onSubmit} className="form">
                {({ submitting, pristine, values, change }) => (
                    <>
                    <Field name="type" type="hidden" defaultValue="volunteer" />
                    <div className="container">
                        <h2 className="main-title main-title--border-top">{t("Dla wolontariuszy", "For volunteers")}</h2>
                        <div className="form__content">
                            <div className="row">
                                <div className="col-md-4 form__el">
                                    <Field name="imie" type="text" placeholder={t("Imię*", "Name*")} />
                                    <ErrorComponent name="imie" />
                                </div>
                                <div className="col-md-4 form__el">
                                    <Field name="nazwisko" type="text" placeholder={t("Nazwisko*", "Surname*")} />
                                    <ErrorComponent name="nazwisko" />
                                </div>
                                <div className="col-md-4 form__el">
                                    <Field name="adres_email" type="text" placeholder={t("Adres e-mail*", "E-mail address*")} />
                                    <ErrorComponent name="adres_email" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form__el">
                                    <Field name="numer_telefonu" type="text" placeholder={t("Numer telefonu*", "Phone number*")} />
                                    <ErrorComponent name="numer_telefonu" />
                                </div>
                                <div className="col-md-4 form__el">
                                    <Field name="pesel" type="text" placeholder={t("PESEL*", "PESEL [Civil Registration No.] no.*")} />
                                    <ErrorComponent name="pesel" />
                                </div>
                                <div className="col-md-4 form__el">
                                    <Field name="numer_dowodu" type="text" placeholder={t("Numer dowodu", "ID Card No.")} />
                                    <ErrorComponent name="numer_dowodu" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form__el">
                                    <Field name="ulica" type="text" placeholder={t("Ulica*", "Street*")} />
                                    <ErrorComponent name="ulica" />
                                </div>
                                <div className="col-md-4 form__el">
                                    <Field name="nr_domu_lokalu" type="text" placeholder={t("Nr domu / lokalu*", "Building No.*")} />
                                    <ErrorComponent name="nr_domu_lokalu" />
                                </div>
                                <div className="col-md-4 form__el">
                                    <Field name="kod_pocztowy" type="text" placeholder={t("Kod pocztowy*", "Postal code*")} />
                                    <ErrorComponent name="kod_pocztowy" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 form__el">
                                    <Field name="miasto" type="text" placeholder={t("Miasto*", "City*")} />
                                    <ErrorComponent name="miasto" />
                                </div>
                                <div className="col-md-6 form__el">
                                    <Field name="numer_legitymacji" type="text" placeholder={t("Nr legitymacji studenckiej", "Student's ID card no.")} />
                                    <ErrorComponent name="numer_legitymacji" />
                                </div>
                            </div>
                        </div>

                        <div>
                            <h4 className="form__title">{t("Chcę otrzymać informację o możliwości udziału w Kongresie w Centrum Kongresowym ICE Kraków", "I  would like to receive information on the opportunity to participate in the Summit in the ICE Krakow Congress Centre")}*</h4>
                            <div className="row">
                                <div className="col-md-2 col-lg-1 form__el">
                                    <label className="checkbox">
                                        <Field name="info_ice" type="radio" value="Tak" defaultChecked={data ? data.info_ice == "Tak" : true} />
                                        <span className="checkbox__checkmark checkbox__checkmark--radio"></span>
                                        <span className="checkbox__text">{t("Tak", "Yes")}</span>
                                    </label>
                                </div>
                                <div className="col-md-2 col-lg-1">
                                    <label className="checkbox">
                                        <Field name="info_ice" type="radio" value="Nie" defaultChecked={data ? data.info_ice == "Nie" : false} />
                                        <span className="checkbox__checkmark checkbox__checkmark--radio"></span>
                                        <span className="checkbox__text">{t("Nie", "No")}</span>
                                    </label>
                                </div>
                            </div>
                            <ErrorComponent name="info_ice" />
                        </div>

                        <div className="form__info">
                            <p>* {t("pola obowiązkowe", "Obligatory fields")}</p>
                        </div>
                    </div>
                
                    <div className="container">
                    
                        <div className="form__option">
                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="regulamin" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t(<>Oświadczam, że zapoznałem/am się z treścią <a href="https://oees.pl/wp-content/uploads/2020/09/Regulamin-Kongres-Open-Eyes-Economy-Summit-5-2.pdf" target="_blank">Regulaminu Kongresu Open Eyes Economy Summit</a> i akceptuję zawarte w nim warunki.</>, <>I declare that I have read the <a href="https://oees.pl/wp-content/uploads/2020/09/Regulamin-OEES-5-EN.pdf" target="_blank">Regulations of the Open Eyes Economy Summit</a> and I accept the terms and conditions contained therein</>)}</p>
                                    <ErrorComponent name="regulamin" />
                                </div>
                            </div>

                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="polityka_prywatnosci" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t(<>Oświadczam, że zapoznałem/am się i akceptuję <a href="https://oees.pl/wp-content/uploads/2020/09/Polityka-Prywatnosci.PL_.pdf" target="_blank">Politykę Prywatności</a> Kongresu Open Eyes Economy Summit</>, <>I declare that I have read and accept the <a href="https://oees.pl/wp-content/uploads/2020/09/Privacy-Policy-2020.pdf" target="_blank">Privacy Policy</a> of the Open Eyes Economy Summit</>)}.</p>
                                    <ErrorComponent name="polityka_prywatnosci" />
                                </div>
                            </div>
                            
                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="przetwarzanie_danych" />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t("Oświadczam, że wyrażam zgodę na przetwarzanie przez Administratora Fundację Gospodarki i Administracji Publicznej z siedzibą w Krakowie (KRS 0000232184) moich danych osobowych w celu rejestracji mojego uczestnictwa w „Kongresie Open Eyes Economy”, na warunkach określonych w Polityce Prywatności zamieszczonej na stronie www.oees.pl", "I declare that I consent to the processing by the Administrator of the Foundation for Economy and Public Administration based in Krakow (KRS 0000232184) of my personal data for the purpose of registration of my participation in the “Open Eyes Economy Summit” under the conditions specified in the Privacy Policy available at www.oees.pl")}.</p>
                                    <ErrorComponent name="przetwarzanie_danych" />
                                </div>
                            </div>

                            <div class="form__el">
                                <label class="checkbox">
                                    <Field type="checkbox" name="marketing" defaultChecked={data ? data.marketing : false} />
                                    <span class="checkbox__checkmark"></span>
                                    <span class="checkbox__text" style={{color: '#009FE3'}}><strong>{t("Zgoda wymagana", "Required consent")}</strong></span>
                                </label>
                                <div class="consent-text">
                                    <p>{t("Oświadczam, że wyrażam zgodę na przetwarzanie przez Administratora Fundację Gospodarki i Administracji Publicznej z siedzibą w Krakowie (KRS 0000232184) moich danych osobowych obejmujących adres e-mail w celach marketingowych związanych z organizacją „Kongresu Open Eyes Economy”, na warunkach określonych w Polityce Prywatności zamieszczonej na stronie www.oees.pl", "I declare that I consent to the processing by the Administrator of the Foundation for Economy and Public Administration based in Krakow (KRS 0000232184) of my personal data including e-mail address for marketing purposes under the conditions specified in the Privacy Policy available at www.oees.pl")}.</p>
                                    <ErrorComponent name="marketing" />
                                </div>
                            </div>
                        </div>
                        <div className="register-info">{t("Po rejestracji na Twój adres e-mail wyślemy umowę o wolontariat.", "After the registration we send you an e-mail with volunteer agreement.")}</div>
                        <div className="btn-center">
                            <button type="submit" className="primary-button" disabled={submitting}>
                                {data ? t('Aktualizuj dane', 'Update details') : t('Zarejestruj się', 'Register')}
                            </button>
                        </div>
                    </div>
                </>)}
                </Form>
            </main>
        </Layout>
    )
}