import {Type} from './Type'

import {BooleanValidator} from './BooleanValidator'
import {ArrayValidator} from './ArrayValidator'
import {ObjectValidator} from './ObjectValidator'
import {StringValidator} from './StringValidator'
import {IntegerValidator} from './IntegerValidator'

import {ErrorMessage} from './ErrorMessage'
import {ErrorConverter} from './ErrorConverter'

class Validate
{
	schema;
	validator;
	errors = {};
	values = {};

	constructor(schema)
	{
		this.schema = schema;
	}

	test(input)
	{
		this.input = input;
		this.errors = this.__validate(input, this.schema);
		return this;
	}

	__validate(input, schema, key = '', prevPath = '', errors = {})
	{
		schema = schema instanceof Type ? schema.schema() : schema

		switch (schema.type)
		{
			case 'object':
                let is_object = o => o.constructor === Object;

				if ((!is_object(input) && input.length)) {
					let errKey = !prevPath.length ? 'root' : prevPath
					errors[errKey] = ErrorMessage.translate('object')
				}

				if (schema.validators !== undefined) {
					let validator = new ObjectValidator(input, this.input)
					for (let validatorName in schema.validators) {
                        let options = schema.validators[validatorName]
						if (!Array.isArray(options)) {
							options = [options];
						}

						let err = validator[validatorName].apply(validator, options)
						if (err) {
							errors[prevPath] = ErrorMessage.translate(`object.${validatorName}`, options, this.value);
						}
					}
				}
				
				for (let field in schema.items) {
                    
                    let itemSchema = schema.items[field]
                    itemSchema = (is_object(itemSchema) && typeof itemSchema.schema == 'function') ? itemSchema.schema() : itemSchema

					let additionalCondition = itemSchema.requiredWhenExist
										    || itemSchema.requiredWhenNotExist
											|| itemSchema.requiredWhenEqual
											|| itemSchema.requiredWhenNotEqual
											|| itemSchema.requiredWhenIn
											|| itemSchema.requiredWhenNotIn
											|| itemSchema.requiredWhenGreater
											|| itemSchema.requiredWhenLower

					let fpath = !prevPath.length ? field : `${prevPath}.${field}`;
					if (input[field] !== undefined) {
						this.values[fpath] = input[field];
					}

					if (additionalCondition) {
						let additionalField, additionalValue;
						
						switch (true) {
							case Array.isArray(itemSchema.requiredWhenNotIn):
								[additionalField, additionalValue] = itemSchema.requiredWhenNotIn;
								itemSchema.required = additionalValue.indexOf(this.values[additionalField]) == -1;
								break;
							case Array.isArray(itemSchema.requiredWhenIn):
								[additionalField, additionalValue] = itemSchema.requiredWhenIn;
								itemSchema.required = additionalValue.indexOf(this.values[additionalField]) != -1;
								break;
							case Array.isArray(itemSchema.requiredWhenEqual):
								[additionalField, additionalValue] = itemSchema.requiredWhenEqual;
								itemSchema.required = this.values[additionalField] == additionalValue;
								break;
							case Array.isArray(itemSchema.requiredWhenNotEqual):
								[additionalField, additionalValue] = itemSchema.requiredWhenNotEqual;
								itemSchema.required = this.values[additionalField] != additionalValue;
								break;
							case Array.isArray(itemSchema.requiredWhenGreater):
								[additionalField, additionalValue] = itemSchema.requiredWhenGreater;
								itemSchema.required = this.values[additionalField] > additionalValue;
								break;
							case Array.isArray(itemSchema.requiredWhenLower):
								[additionalField, additionalValue] = itemSchema.requiredWhenLower;
								itemSchema.required = this.values[additionalField] < additionalValue;
								break;
							case Array.isArray(itemSchema.requiredWhenExist):
								[additionalField] = itemSchema.requiredWhenExist;
								itemSchema.required = this.values[additionalField] !== undefined;
								break;
							case Array.isArray(itemSchema.requiredWhenNotExist):
								[additionalField] = itemSchema.requiredWhenNotExist;
								itemSchema.required = this.values[additionalField] == undefined;
								break;
						}
					}

					if (itemSchema.required !== undefined && itemSchema.required && input[field] == undefined) {
						let errorPath = !prevPath.length ? field : `${prevPath}.${field}`;
						if (errors[errorPath] == undefined) {
							errors[errorPath] = ErrorMessage.translate(`${itemSchema.type}.required`, [], '');
						}
						continue;
					} else if (itemSchema.required !== undefined && !itemSchema.required && input[field] == undefined) {
						continue;
					}

					this.__validate(input[field], itemSchema, field, !prevPath.length ? field : `${prevPath}.${field}`, errors)
				}
				break;
			
			case 'array':
			
			if (!Array.isArray(input)) {
				let errKey = !prevPath.length ? 'root' : prevPath
				errors[errKey] = ErrorMessage.translate('array');
				break;
					//throw new Exception('Provided '.(empty(prevPath) ? 'root ' : 'field ' . prevPath . ' ').'is not type of array!');
				}

				if (schema.validators !== undefined) {
					let validator = new ArrayValidator(input, this.input)
					for (let validatorName in schema.validators) {
						let options = schema.validators[validatorName]
						if (!Array.isArray(options)) {
							options = [options];
						}

						let err = validator[validatorName].apply(validator, options)
						if (!err && errors[prevPath] == undefined) {
							errors[prevPath] = ErrorMessage.translate(`array.${validatorName}`, options, this.value);
						}
					}
				}

				let itemSchema = schema.items;

				if (Array.isArray(input)) {
					for (let index in input) {
						let value = input[index];
						this.__validate(value, itemSchema, index, !prevPath.length ? index : `${prevPath}.${index}`, errors)
					}
				}
				break;

			case 'string':
				if (typeof input !== 'string') {
					let errKey = !prevPath.length ? 'root' : prevPath;
					errors[errKey] = ErrorMessage.translate('string');
					break;
					//throw new Exception('Provided '.(empty(prevPath) ? 'root ' : 'field ' . prevPath . ' ').'is not type of string!');
				}
				
				if (Object.keys(schema.validators).length) {
					let validator = new StringValidator(input, this.input);
					for (let validatorName in schema.validators) {
						let options = schema.validators[validatorName]
						if (!Array.isArray(options)) {
							options = [options];
						}

						let err = validator[validatorName].apply(validator, options)
						if (!err && errors[prevPath] == undefined) {
							errors[prevPath] = ErrorMessage.translate(`string.${validatorName}`, options, this.value);
						}
					}
				}
				break;

			case 'integer':
				if (typeof input !== 'integer') {
					let errKey = !prevPath.length ? 'root' : prevPath;
					errors[errKey] = ErrorMessage.translate('integer');
					break;
					//throw new Exception('Provided input '.prevPath.' is not type of integer!');
				}
				validator = new IntegerValidator(input, this.input);

				for (let validatorName in schema.validators) {
					let options = schema.validators[validatorName]
					if (!Array.isArray(options)) {
						options = [options];
					}

					let err = validator[validatorName].apply(validator, options)
					if (!err && errors[prevPath] == undefined) {
						errors[prevPath] = ErrorMessage.translate(`integer.${validatorName}`, options, this.value);
					}
				}
				break;

			case 'boolean':
				if (typeof input !== 'boolean') {
					let errKey = !prevPath.length ? 'root' : prevPath;
					errors[errKey] = ErrorMessage.translate('boolean');
					break;
					//throw new Exception('Provided input '.prevPath.' is not type of boolean!');
				}

				let validator = new BooleanValidator(input, this.input);

				for (let validatorName in schema.validators) {
					let options = schema.validators[validatorName]
					if (!Array.isArray(options)) {
						options = [options];
					}

					let err = validator[validatorName].apply(validator, options)
					if (!err && errors[prevPath] == undefined) {
						errors[prevPath] = ErrorMessage.translate(`boolean.${validatorName}`, options, this.value);
					}
				}
				break;

			default:
				let errKey = !prevPath.length ? 'root' : prevPath;
				errors[errKey] = ErrorMessage.translate('missingType');
				break;
				//throw new Exception('Missing type '.schema['type'].' declaration');
				break;
		}

		return Object.keys(errors).length ? errors : true;
	}

	// toJson(schema = false)
	// {
	// 	if (count(func_get_args()) == 0) {
	// 		return this.toJson(this.schema);
	// 	}

	// 	schema = is_object(schema) ? schema.schema() : schema;

	// 	switch (schema['type'])
	// 	{
	// 		case 'object':
	// 			foreach (schema['items'] as field => &itemSchema) {
	// 				itemSchema = is_object(itemSchema) ? itemSchema.schema() : itemSchema;
	// 				schema['items'][field] = this.toJson(itemSchema);
	// 			}

	// 			if (isset(schema['validators'])) {
	// 				foreach (array_keys(schema['validators']) as validatorName) {
	// 					if (!ObjectValidator.hasValidator(validatorName) || ObjectValidator.isServer(validatorName)) {
	// 						unset(schema['validators'][validatorName]);
	// 					}
	// 				}
	// 			}

	// 			return schema;
	// 			break;
			
	// 		case 'array':
	// 			schema['items'] = this.toJson(schema['items']);
	// 			if (isset(schema['validators'])) {
	// 				foreach (array_keys(schema['validators']) as validatorName) {
	// 					if (!ArrayValidator.hasValidator(validatorName) || ArrayValidator.isServer(validatorName)) {
	// 						unset(schema['validators'][validatorName]);
	// 					}
	// 				}
	// 			}
	// 			return schema;
	// 			break;

	// 		case 'integer':
	// 			schema['items'] = this.toJson(schema['items']);
	// 			if (isset(schema['validators'])) {
	// 				foreach (array_keys(schema['validators']) as validatorName) {
	// 					if (!IntegerValidator.hasValidator(validatorName) || IntegerValidator.isServer(validatorName)) {
	// 						unset(schema['validators'][validatorName]);
	// 					}
	// 				}
	// 			}
	// 			break;
				
	// 		case 'boolean':
	// 			schema['items'] = this.toJson(schema['items']);
	// 			if (isset(schema['validators'])) {
	// 				foreach (array_keys(schema['validators']) as validatorName) {
	// 					if (!BooleanValidator.hasValidator(validatorName) || BooleanValidator.isServer(validatorName)) {
	// 						unset(schema['validators'][validatorName]);
	// 					}
	// 				}
	// 			}
	// 			break;

	// 		case 'string':
	// 			if (isset(schema['validators'])) {
	// 				foreach (array_keys(schema['validators']) as validatorName) {
	// 					if (!StringValidator.hasValidator(validatorName) || StringValidator.isServer(validatorName)) {
	// 						unset(schema['validators'][validatorName]);
	// 					}
	// 				}
	// 			}
	// 			return schema;
	// 			break;
	// 	}

	// 	return schema;
	// }

	hasErrors()
	{
		return this.errors !== true;
	}

	getErrors()
	{
		return new ErrorConverter(this.errors);
	}
}

export {
    Validate
}