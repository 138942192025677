import React, { Component } from 'react'

import createDecorator from 'final-form-focus'
import arrayMutators from 'final-form-arrays'
import { Form as FinalForm, FormSpy, Field as FormField } from 'react-final-form'
import { FieldArray as FinalFieldArray } from 'react-final-form-arrays'
import { OnChange } from 'react-final-form-listeners'
import { Validate } from 'lib/validation/Validate'
import { Cache } from 'lib/validation/Cache'

const UIFormField = ({ required, hasError, title = '', right = false, help = false, error = false, children }) => (
	children
)

const FieldArray = ({ action, help = '', title = '', children, ...rest }) => {
	return (
		<FinalFieldArray {...rest}>
			{
				(prps => (
					<UIFormField hideTitle={rest.type == 'checkbox'} name={rest.name} right={typeof action == 'function' ? action(prps) : false} help={help} title={title} error={<ErrorComponent name={rest.name} />}>
						{children(prps)}
					</UIFormField>
				))
			}
		</FinalFieldArray>
	)
}

const Field = ({ type, inline = false, required = false, format, title, help, ...props }) => {
	const getControl = (type, input) => {
		switch (type) {
			case 'text':
				return <input {...props} {...input} className="input" />;
				break;
			case 'hidden':
				return <input type="hidden" {...props} {...input} />;
				break;
			case 'password':
				return <input type="password" {...props} {...input} />;
				break;
			case 'checkbox':
				return <input type="checkbox" {...props} {...input} />;
				break;
			case 'radio':
				return <input type="radio"  {...input} {...props} />;
				break;
		}
	}

	let errFieldName = props.name.replace(/\[/g, ".").replace(/\]/g, "");

	return (
		<FormSpy>
			{({ submitErrors }) => (
				<FormField
					{...props}
					children={({ meta: { touched, error, submitError }, input }) => (
						<UIFormField inline={inline} name={props.name} hasError={touched && submitErrors != undefined && submitErrors[errFieldName] != undefined} hideTitle={type == 'checkbox'} required={required} help={help} title={title} error={<ErrorComponent name={props.name} />}>
							{getControl(type, input)}
						</UIFormField>
					)} />
			)}
		</FormSpy>
	)
}

const ErrorComponent = ({ name }) => {
	let errFieldName = name.replace(/\[/g, ".").replace(/\]/g, "");
	return (
		<FormSpy>
			{({ submitErrors }) => (
				<FormField
					name={name}
					subscription={{ touched: true }}
					render={({ meta: { touched, error } }) => {
						return touched && submitErrors != undefined && submitErrors[errFieldName] != undefined ? (
							<span style={{ color: "red" }}>{submitErrors[errFieldName]}</span>
						) : null
					}}
				/>
			)}
		</FormSpy>
	);
};

const Condition = ({ when, is, children, elseSet, to }) => (
	<React.Fragment>
		<FormField name={when} subscription={{ value: true }}>
			{({ input: { value } }) => {
				return is(value) ? children : null
			}}
		</FormField>
		<FormField name={elseSet} subscription={{ value: true }}>
			{(
				{ input: { onChange } }
			) => (
					<OnChange name={when}>
						{value => {
							if (!is(value)) {
								onChange(to)
							}
						}}
					</OnChange>
				)}
		</FormField>
	</React.Fragment>
)

const focusOnError = createDecorator()

class Form extends React.Component {
	state = {
		loading: false,
		loaded: false,
		error: false,
		initial: {}
	}

	componentDidMount() {
		const { onLoad } = this.props

		if (this.props.onLoad !== undefined) {
			this.setState({ loading: true })
			onLoad()
				.then(initial => this.setState({ initial, loaded: true, error: false, loading: false }))
				.catch(err => this.setState({ loaded: false, error: true, loading: false }))

		} else {
			this.setState({ loaded: true })
		}
	}

	onValidate = (schema, values) => {
		return new Promise((res, rej) => {
			if (typeof schema === 'undefined') {
				res();
				return;
			}

			schema = Cache.get(schema)

			const validate = new Validate(schema)
			validate.test(values)

			if (validate.hasErrors()) {
				rej(validate.getErrors().asDotArray())
			} else {
				res();
			}
		})
	}

	onSubmit = (values) => {
		if (this.props.onSubmit == undefined) {
			return new Promise((res, rej) => {
				res(values)
			})
		}

		return new Promise((res, rej) => {
			this.onValidate(this.props.schema, values)
				.then(() => {
					this.props.onSubmit(values).then(async response => {
						if (!response.ok) {
							let errs = await response.json()
							this.props.onError != undefined && this.props.onError(errs)
							res(errs)
							return;
						}

						let data;
						try {
							data = await response.json()
						} catch (err) {
							data = {}
						}
						this.props.onSuccess != undefined && this.props.onSuccess({ response, data })
						res(data)
					})
						.catch(async err => {
							this.props.onError != undefined && this.props.onError()
							rej(err)
							console.log(err)
						})
				})
				.catch(err => {
					console.log(err)
					this.props.onError != undefined && this.props.onError()
					res(err)
				})
		})
	}

	render() {
		if (this.state.error) {
			return (
				<div>Wystąpił błąd przy ładowaniu formularza</div>
			)
		}

		if (this.state.loading && this.props.onLoad != undefined) {
			return (
				<div>Trwa ładowanie danych</div>
			)

		}

		if (!this.state.loaded && this.props.onLoad != undefined) {
			return null;
		}

		return (
			<FinalForm
				onSubmit={this.onSubmit}
				decorators={[focusOnError]}
				initialValues={this.props.initial != undefined ? this.props.initial : this.state.initial}
				mutators={{ ...arrayMutators, ...this.props.mutators }}
				render={({
					handleSubmit,
					form: {
						mutators
					},
					pristine,
					submitting,
					values,
					submitErrors,
					change
				}) => (
						<form onSubmit={handleSubmit} autoComplete="off">
							{
								this.props.children({
									change,
									mutators,
									pristine,
									submitting,
									values,
									submitErrors
								})
							}
						</form>
					)} />
		)
	}
}

export { Form, Field, FieldArray, Condition, ErrorComponent }